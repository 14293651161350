<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增流量池扩容记录"
      @ok="handleSubmit"
    >
      <a-form
        class="iotplt-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item :label="amountTitle">
          <a-input-number
            style="width: 100%"
            :min="1"
            :max="10000000"
            :step="1"
            :precision="0"
            v-decorator="['amount', {
              rules: [
                { required: true, message: '请输入容量' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="beginAtTitle">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledBeginAt"
            v-decorator="['begin_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="endAtTitle">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledEndAt"
            v-decorator="['end_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`备注`">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' },
                { required: true, message: '请输入备注' }
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createAgencyTrafficPoolExpandRecord } from '@/api/traffic_pool'
import { formatDate } from '@/utils/time'

export default {
  name: 'NewAgencyTrafficPoolExpandRecord',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      amountTitle: '扩容容量(GB)',
      beginAtTitle: '有效期开始日期',
      endAtTitle: '有效期结束日期',
      agent: {},
      submitting: false,
      form: this.$form.createForm(this, { name: 'agency_trafficpool_expandrecord' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    },

    trafficPoolId() {
      return parseInt(this.$route.params.traffic_pool_id)
    }
  },
  created() {
  },
  methods: {
    disabledBeginAt(current) {
      return current && current > this.$moment().endOf('day')
    },

    disabledEndAt(current) {
      return current && current < this.$moment().endOf('day')
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            traffic_pool_id: this.trafficPoolId,
            begin_at: formatDate(values.begin_at),
            end_at: formatDate(values.end_at)
          }
          createAgencyTrafficPoolExpandRecord(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
